<template>
  <div class="cbx-wrapper">
    <input
      type="checkbox"
      :name="id"
      :id="id"
      :checked="checked"
      :disabled="disabled"
      @change="$emit('change', $event.target.checked)"
    />
    <label class="cbx-label" :for="id">
      <span class="cbx-tick"></span>
      {{ label }}
    </label>
  </div>
</template>
<script>
export default {
  name: 'VueCheck',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    checked: Boolean,
    disabled: Boolean,
  },
};
</script>
<style lang="scss" scoped>
$scale: 0.8;
$checkboxSize: $scale * 25px;
$spaceBetweenBoxAndText: $scale * 6px;
$checkboxColorOnHover: rgba(0, 119, 255, 0.2); // rgba(0, 119, 255, 0.06)
$checkboxBgColorState-unchecked: transparent;
$checkboxBgColorState-checked: #07f;
$checkboxBorderColorState-unchecked: #cccfdb;

$checkmarkHeight: $checkboxSize / 2; // 12px
$checkmarkWidth: $checkmarkHeight / 2; // 6px
$checkmarkThickness: 2px;
$checkmarkColorState-unchecked: transparent;
$checkmarkColorState-checked: #fff;
$checkmarkColorState-hover: transparent;
// needs to be adjusted sometimes
$checkmarkPosLeft: 25%;
$checkmarkPosTop: 52%;

$fontSize: $scale * 22px;

.cbx-wrapper {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; // don't make text highlightable
  margin-right: 10px;
  width: fit-content;
  height: fit-content;

  // hover over the whole enabled checkbox
  &:hover input[type='checkbox']:not([disabled]) {
    + .cbx-label {
      transition: all 0.2s ease;
      background: $checkboxColorOnHover;

      &:before {
        transition: all 0.2s ease;
        border-color: $checkboxBgColorState-checked;
      }
    }
  }

  input[type='checkbox'] {
    display: none; // hide original checkbox

    // checkbox
    + .cbx-label:before {
      content: ''; // when it's unchecked
      display: inline-block;
      width: $checkboxSize;
      height: $checkboxSize;
      border-radius: $scale * 5px;
      border: 1px solid $checkboxBorderColorState-unchecked;
      transform: scale(1);
      transition: all 0.2s ease;
    }

    &:checked {
      // checkbox
      + .cbx-label:before {
        content: ''; // when it's checked
        background: $checkboxBgColorState-checked;
        border-color: $checkboxBgColorState-checked;
        animation: wave 0.4s cubic-bezier(0.64, 0.11, 0.26, 0.96);
      }

      // checkmark
      + .cbx-label .cbx-tick {
        &:after {
          animation: check 0.8s;
          border-top-color: $checkmarkColorState-checked;
          border-right-color: $checkmarkColorState-checked;
        }
      }
    }

    &:disabled {
      + .cbx-label {
        opacity: 0.6;
        cursor: not-allowed;
      }

      &:checked {
        // checkbox
        + .cbx-label:before {
          background: $checkboxBgColorState-unchecked;
          border-color: $checkboxBgColorState-checked;
        }

        // checkmark
        + .cbx-label .cbx-tick:after {
          border-top-color: $checkboxBgColorState-checked;
          border-right-color: $checkboxBgColorState-checked;
        }
      }
    }
  }

  // Label base styling
  .cbx-label {
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    outline: none;
    align-items: center;
    justify-content: baseline;
    border-radius: 6px;
    margin: 0;
    position: relative;
    cursor: pointer;
    font-size: $fontSize;
    // transition: all 0.2s ease;

    // checkmark-box
    .cbx-tick {
      position: absolute;
      display: block;
      width: $checkboxSize;
      height: $checkboxSize;
      background: $checkboxBgColorState-unchecked;
      border-radius: $scale * 5px;

      // checkmark
      &:after {
        // Positioning
        content: '';
        display: block;
        height: $checkmarkHeight;
        width: $checkmarkWidth;
        position: absolute;
        left: $checkmarkPosLeft;
        top: $checkmarkPosTop;

        transform: scaleX(-1) rotate(185deg + -45deg);
        transform-origin: left top;
        border-right: $checkmarkThickness solid $checkmarkColorState-unchecked;
        border-top: $checkmarkThickness solid $checkmarkColorState-unchecked;
      }
    }
  }
}

// Create animation called 'wave'
@-moz-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@-webkit-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@-o-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}

// Create animation called 'check'
@-o-keyframes check {
  0% {
    height: 0;
    width: 0;
  }
  25% {
    height: 0;
    width: $checkmarkWidth;
  }
  50% {
    height: $checkmarkHeight;
    width: $checkmarkWidth;
  }
}
@-webkit-keyframes check {
  0% {
    height: 0;
    width: 0;
  }
  25% {
    height: 0;
    width: $checkmarkWidth;
  }
  50% {
    height: $checkmarkHeight;
    width: $checkmarkWidth;
  }
}
@-moz-keyframes check {
  0% {
    height: 0;
    width: 0;
  }
  25% {
    height: 0;
    width: $checkmarkWidth;
  }
  50% {
    height: $checkmarkHeight;
    width: $checkmarkWidth;
  }
}
@keyframes check {
  0% {
    height: 0;
    width: 0;
  }
  25% {
    height: 0;
    width: $checkmarkWidth;
  }
  50% {
    height: $checkmarkHeight;
    width: $checkmarkWidth;
  }
}
</style>
