<template>
  <div class="image-editor">
    <VueImageInput :data="editorData" />
    <VueImageCropper :data="editorData" :cropperOptions="cropperOptions" />
  </div>
</template>

<script>
import VueImageInput from '@/components/VueImageEditor/components/VueImageInput.vue';
import VueImageCropper from '@/components/VueImageEditor/components/VueImageCropper.vue';

export default {
  name: 'VueImageEditor',
  props: {
    cropperOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    VueImageInput,
    VueImageCropper,
  },
  data() {
    return {
      editorData: {
        cropped: false,
        cropping: false,
        loaded: false,
        name: '',
        type: '',
        url: '',
      },
    };
  },
  watch: {
    editorData: {
      deep: true,
      handler(newVal) {
        this.$emit('update:source', newVal.url);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.image-editor {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: relative;
  z-index: 10;
}
</style>
