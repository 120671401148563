<template>
  <date-pick
    :weekdays="weekdays"
    nextMonthCaption="Sonraki Ay"
    prevMonthCaption="Önceki Ay"
    v-model="modelDate"
    :startPeriod="startPeriod"
    :displayFormat="'DD.MM.YYYY'"
    :format="format"
    :months="months"
    :isDateDisabled="isDateDisabled"
  >
    <template v-slot:default="{ toggle, inputValue }">
      <slot />
    </template>
  </date-pick>
</template>
<script>
import DatePick from 'vue-date-pick';
import 'vue-date-pick/src/vueDatePick.scss';

export default {
  name: 'BrandDatePicker',
  components: {
    DatePick,
  },
  props: {
    value: {
      type: String,
    },
    startYear: {
      default: new Date().getFullYear(),
    },
    startMonth: {
      default: new Date().getMonth(),
    },
    isDateDisabled: {
      type: Function,
    },
    format: {
      type: String,
      default: 'YYYY-M-D',
    },
  },
  data() {
    return {
      modelDate: this.value,
      weekdays: ['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz'],
      months: [
        'Ocak',
        'Şubat',
        'Mart',
        'Nisan',
        'Mayıs',
        'Haziran',
        'Temmuz',
        'Ağustos',
        'Eylül',
        'Ekim',
        'Kasım',
        'Aralık',
      ],
    };
  },
  computed: {
    valueSplitter() {
      if (this.format === 'DD.MM.YYYY') {
        return '.';
      } else return '-';
    },
    startPeriod() {
      let month = this.startMonth;
      let year = this.startYear;
      if (this.value) {
        let spl = this.value.split(this.valueSplitter);
        if (this.format === 'DD.MM.YYYY') {
          if (spl && spl.length > 1) {
            month = Number(spl[1]) - 1;
            year = Number(spl[2]);
          }
        } else if (this.format === 'YYYY-M-D') {
          month = Number(spl[1]) - 1;
          year = Number(spl[0]);
        }
      }
      return {
        month,
        year,
      };
    },
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.modelDate = newVal;
        }
      },
    },
    modelDate: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('input', newVal);
        }
      },
    },
  },
};
</script>
<style>
.vdpArrowPrev:after {
  border-right-color: #e47b49;
}
.vdpArrowNext:after {
  border-left-color: #e47b49;
}
.vdpCellContent {
  border-radius: 15%;
}
.vdpCell.selectable:hover .vdpCellContent {
  background: linear-gradient(315deg, #d20051, #ffa300);
  opacity: 0.8;
}
.vdpCell.selected .vdpCellContent {
  background: linear-gradient(315deg, #d20051, #ffa300);
}
.vdpCell.today {
  color: #e47b49;
}
</style>
