<template>
  <transition name="fade">
    <div class="image-cropper" v-if="data.loaded">
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
      <div class="editor">
        <div class="toolbar toolbar-header">
          <div class="toolbar-buttons">
            <button
              class="toolbar__button"
              data-action="rotate-left"
              title="Rotate Left (L)"
              @click="click"
            >
              <span class="material-icons">
                rotate_left
              </span>
            </button>
            <button
              class="toolbar__button"
              data-action="rotate-right"
              title="Rotate Right (R)"
              @click="click"
            >
              <span class="material-icons">
                rotate_right
              </span>
            </button>
            <button
              class="toolbar__button"
              data-action="flip-horizontal"
              title="Flip Horizontal"
              @click="click"
            >
              <span class="material-icons">
                flip
              </span>
            </button>
            <button
              class="toolbar__button toolbar__button-rotate-90"
              data-action="flip-vertical"
              title="Flip Vertical"
              @click="click"
            >
              <span class="material-icons">
                flip
              </span>
            </button>
          </div>

          <div class="toolbar-buttons">
            <button class="toolbar__button" data-action="crop" title="Crop" @click="click">
              Bitti
            </button>
          </div>
        </div>
        <div class="canvas">
          <img ref="image" :alt="data.name" :src="data.url" @loadstart="start" @load="start" />
        </div>
        <div v-if="cropper" class="toolbar toolbar-footer" @click="click">
          <div class="toolbar-buttons">
            <button
              class="toolbar__button toolbar__button-warning"
              data-action="dismiss"
              title="Dismiss"
              @click="click"
            >
              Vazgeç
            </button>
          </div>
          <div class="zoom-holder">
            <vue-slider v-bind="sliderOptions" v-model="zoomPercentage"></vue-slider>
          </div>
          <div class="toolbar-buttons">
            <button
              class="toolbar__button toolbar__button-warning"
              data-action="restore"
              title="Restore"
              @click="click"
            >
              Sıfırla
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
export default {
  name: 'VueImageCropper',
  props: {
    cropperOptions: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    VueSlider,
  },
  data() {
    return {
      canvasData: null,
      cropBoxData: null,
      croppedData: null,
      cropper: null,
      zoomPercentage: 0,
      sliderOptions: {
        dotSize: 14,
        width: 'auto',
        height: 6,
        contained: false,
        direction: 'ltr',
        data: null,
        min: -10,
        max: 10,
        interval: 1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: 'active',
        tooltipPlacement: 'top',
        tooltipFormatter: val => `${val}x`,
        useKeyboard: false,
        keydownHook: null,
        dragOnClick: false,
        enableCross: true,
        fixed: false,
        minRange: void 0,
        maxRange: void 0,
        order: true,
        marks: false,
        dotOptions: void 0,
        process: true,
        dotStyle: void 0,
        railStyle: {
          height: '1px',
        },
        processStyle: {
          'background-color': '#e74c3c',
        },
        tooltipStyle: {
          'background-color': '#e74c3c',
        },
        stepStyle: void 0,
        stepActiveStyle: void 0,
        labelStyle: void 0,
        labelActiveStyle: void 0,
      },
    };
  },
  methods: {
    click({ target }) {
      const { cropper } = this;
      const action =
        target.getAttribute('data-action') || target.parentElement.getAttribute('data-action');

      switch (action) {
        case 'move':
          cropper.setDragMode(action);
          break;

        case 'zoom-in':
          cropper.zoom(0.1);
          break;

        case 'zoom-out':
          cropper.zoom(-0.1);
          break;

        case 'rotate-left':
          cropper.rotate(-90);
          break;

        case 'rotate-right':
          cropper.rotate(90);
          break;

        case 'flip-horizontal':
          cropper.scaleX(-cropper.getData().scaleX || -1);
          break;

        case 'flip-vertical':
          cropper.scaleY(-cropper.getData().scaleY || -1);
          break;
        case 'dismiss':
          this.reset(true);
          this.stop();
          break;
        case 'restore':
          this.restore();
          break;
        case 'crop':
          this.crop();
          break;

        default:
      }
    },

    start() {
      const { data } = this;

      if (data.cropped || this.cropper) {
        return;
      }

      this.restore();
      document.querySelector('body').style.overflow = this.data.loaded ? 'hidden' : 'unset';

      const options = {
        ...this.cropperOptions,
      };

      this.zoomPercentage = 0;

      this.cropper = new Cropper(this.$refs.image, {
        ...options,
        ready: () => {
          if (this.croppedData) {
            this.cropper
              .crop()
              .setData(this.croppedData)
              .setCanvasData(this.canvasData)
              .setCropBoxData(this.cropBoxData);

            this.croppedData = null;
            this.canvasData = null;
            this.cropBoxData = null;
          }
        },

        crop: ({ detail }) => {
          if (detail.width > 0 && detail.height > 0 && !data.cropping) {
            this.update({
              cropping: true,
            });
          }
        },
      });
    },

    stop() {
      this.reset();
      if (this.cropper) {
        this.cropper.destroy();
        this.canvasData = null;
        this.cropBoxData = null;
        this.croppedData = null;
        this.cropper = null;
      }
    },

    crop() {
      const { cropper, data } = this;

      if (data.cropping) {
        this.croppedData = cropper.getData();
        this.canvasData = cropper.getCanvasData();
        this.cropBoxData = cropper.getCropBoxData();

        this.update({
          cropped: true,
          cropping: false,
          previousUrl: data.url,
          url: cropper
            .getCroppedCanvas(
              data.type === 'image/png'
                ? {}
                : {
                    fillColor: '#fff',
                  },
            )
            .toDataURL(data.type),
        });
        this.stop();
      }
    },

    clear() {
      if (this.data.cropping) {
        this.cropper.clear();
        this.update({
          cropping: false,
        });
      }
    },

    restore() {
      if (this.cropper) {
        this.isRestore = true;
        this.zoomPercentage = 0;
        this.cropper.zoom(this.zoomPercentage);
        this.cropper.reset();
      }
    },

    reset(full) {
      full
        ? this.update({
            cropped: false,
            cropping: false,
            loaded: false,
            name: '',
            previousUrl: '',
            type: '',
            url: '',
          })
        : this.update({
            cropped: false,
            cropping: false,
            loaded: false,
            name: '',
            previousUrl: '',
            type: '',
          });
      this.zoomPercentage = 0;

      document.querySelector('body').style.overflow = this.data.loaded ? 'hidden' : 'unset';
    },

    update(data) {
      Object.assign(this.data, data);
    },
  },
  watch: {
    zoomPercentage(newVal, oldVal) {
      if (this.isRestore) {
        this.isRestore = false;

        return;
      }

      const diff = newVal - oldVal;
      if (this.cropper && this.cropper.canvasData) {
        this.cropper.zoom(diff * 0.1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.image-cropper {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: initial;
  background-color: #333;

  .editor {
    height: 100%;
    width: 100%;
    max-width: initial;
    max-height: initial;
    min-height: 480px;
    margin: auto;
    position: relative;

    // @media screen and (min-width: 767px) {
    //   height: auto;
    //   width: 100%;
    //   max-width: 640px;
    //   max-height: 100%;
    // }

    .toolbar {
      z-index: 3;
      height: 40px;
      width: 100%;
      box-shadow: 0 2px 70px 0 rgba(0, 0, 0, 0.03);
      background-color: rgba(#333, 0.6);
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      left: 0;
      position: absolute;
      width: 90%;
      left: 50%;
      transform: translateX(-50%);

      &-header {
        top: 10px;
        top: env(safe-area-inset-top);
      }

      &-footer {
        bottom: 10px;
        bottom: env(safe-area-inset-bottom);
        padding-bottom: 10px;
      }

      &-buttons {
        display: flex;
        height: 100%;

        .toolbar__button {
          height: 100%;
          background-color: transparent;
          border: none;
          color: #fff;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          margin: 0 5px;
          align-items: center;
          justify-content: center;
          font-size: 1.1rem;

          &:focus,
          &:hover {
            outline: none;
            background-color: transparent;
            color: #fff;
          }

          &-warning {
            color: #e5472d;
            text-shadow: 0px 1px #23303d, -1px -1px #23303d;

            &:focus,
            &:hover {
              outline: none;
              background-color: transparent;
              color: #e5472d;
            }
          }

          &-rotate-90 {
            span {
              transform: rotate(90deg);
            }
          }
        }
      }

      .zoom-holder {
        flex: 1;
        padding: 0 20px;
      }
    }

    .canvas {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;

      img {
        display: block;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
