<template>
  <label class="image-input" @change="change">
    <input id="file" type="file" accept="image/*" />
  </label>
</template>

<script>
const URL = window.URL || window.webkitURL;
export default {
  name: 'VueImageInput',
  props: {
    data: {
      type: Object,
      default: () => ({
        loaded: false,
        name: '',
        type: '',
        url: '',
      }),
    },
  },
  methods: {
    read(files) {
      return new Promise((resolve, reject) => {
        if (!files || files.length === 0) {
          resolve();
          return;
        }
        const file = files[0];
        if (/^image\/\w+$/.test(file.type)) {
          if (URL) {
            resolve({
              loaded: true,
              name: file.name,
              type: file.type,
              url: URL.createObjectURL(file),
            });
          } else {
            reject(new Error('Your browser is not supported.'));
          }
        } else {
          reject(new Error('Please choose an image file.'));
        }
      });
    },
    change({ target }) {
      this.read(target.files)
        .then(data => {
          target.value = '';
          this.update(data);
        })
        .catch(() => {
          target.value = '';
        });
    },
    update(data) {
      Object.assign(this.data, data);
    },
  },
};
</script>

<style lang="scss" scoped>
.image-input {
  height: 100%;
  width: 100%;
  min-width: 1px;
  min-height: 1px;
  display: flex;
  position: relative;
  pointer-events: initial;

  input[type='file'] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}
</style>
